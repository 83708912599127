<template>
  <div class="d-flex flex-column">
    <h5 class="h5-text mb-2">Редактировать партнера</h5>
    <div class="page-block flex-column mb-6">
      <div class="mb-10 d-flex flex-wrap">
        <v-btn color="secondary" outlined :to="`/admin/partners/${categoryId}/${partnerId}/edit`" class="mr-3 mb-1 white--text">Редактировать партнера</v-btn>
        <v-btn
            color="secondary"
            outlined
            :to="`/admin/partners/${categoryId}/${partnerId}/back-users`"
            class="mr-3 mb-1 white--text">
          Сотрудники бека
        </v-btn>
        <v-btn color="secondary" :to="`/admin/partners/${categoryId}/${partnerId}/subscription`" class="mb-1 white--text">Информация о подписке</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="secondary" v-if="subscribeDetails.id" @click="testReport()" outlined>Проверить отчет</v-btn>
      </div>
      <v-row>
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field label="Номер договора" v-model="subscribeDetails.contract_number" outlined></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field type="number" v-model="subscribeDetails.price" label="Размер вознаграждения за период" outlined></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-menu
            ref="menu"
            v-model="dateSearchMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="subscribeDetails.date"
                label="Дата договора"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="subscribeDetails.date"
              @input="dateSearchMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <div class="mb-4">
        <v-checkbox
          v-model="subscribeDetails.auto_report"
          label="Направлять отчет автоматически"
        ></v-checkbox>
      </div>
      <v-row>
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            label="Календарный день отправки отчета"
            v-model="subscribeDetails.report_day"
            :disabled="!subscribeDetails.auto_report"
            outlined
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-for="(item, index) in feedbacks" :key="index">
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="item.name"
            :disabled="!subscribeDetails.auto_report"
            label="Имя сотрудника, кому отправлять отчет"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="item.email"
            :disabled="!subscribeDetails.auto_report"
            label="Email сотрудника, кому отправлять отчет"
            outlined
            type="email"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-btn
            @click="addFeedback()"
            v-if="!index"
            x-large
            color="secondary"
            :disabled="!subscribeDetails.auto_report"
          >Добавить</v-btn>
          <v-btn
            @click="removeFeedback(index)"
            v-if="index"
            x-large
            color="error"
            :disabled="!subscribeDetails.auto_report"
          >Удалить</v-btn>
        </v-col>

      </v-row>
      <div class="text-center">
        <v-btn
          color="secondary"
          x-large
          width="250"
          depressed
          class="mx-auto"
          @click="save()"
        >
          Сохранить
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import { Http } from '../../../apis/Http'

  export default {
    data() {
      return {
        partnerId: 0,
        dateSearchMenu: false,
        subscribeDetails: {},
        feedbacks: []
      }
    },
    async created() {
      this.partnerId = this.$router.currentRoute.params.id
      this.categoryId = this.$router.currentRoute.params.categoryId
      const res = await Http.get(`/admin/fetch-partner-subscribe/${this.partnerId}/${this.categoryId}`)
      if (res?.data) {
        this.subscribeDetails = res.data.subscribe
        this.feedbacks = res.data.feedbacks
      }
    },
    methods: {
      async save() {
        const res = await Http.post('/admin/save-partner-subscribe', {
          subscribe: this.subscribeDetails,
          feedbacks: this.feedbacks
        })
        this.subscribeDetails.id = res.data.id
        this.showSuccessSystemNotification('Новые данные успешно сохранены')
      },
      addFeedback() {
        this.feedbacks.push({ id: 0, name: '', email: '' })
      },
      removeFeedback(index) {
        this.feedbacks.splice(index, 1)
      },
      testReport() {
        Http.get('/admin/testGenerateReport', {
          params: {
            companyId: this.partnerId
          },
          responseType: 'blob'
        })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            const fileName = response.headers?.['content-disposition'] ? response.headers['content-disposition'].split('filename=')[1] : 'test-report.docx'
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
          .catch(() => {
            this.showErrorSystemNotification('Ooops!')
          })
      }
    }
  }
</script>
